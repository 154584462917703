import {
    SegmentedCarousel,
    useSegmentedCarouselController,
} from '@eventbrite/collection-carousel';
import { FormattedEvent } from '@eventbrite/event-renderer';
import { gettext } from '@eventbrite/i18n';
import { Typography } from '@eventbrite/marmalade';
import React from 'react';
import { LoadingCards } from '../../../../../components/LoadingCards/LoadingCards';
import RankedCardContainer from '../../../../../containers/RankedCardContainer/RankedCardContainer';
import styles from './TopPicksCarousel.module.scss';
import './TopPicksCarousel.scss';

interface TopPicksCarouselProps {
    carouselEvents: FormattedEvent[];
    isLoading: boolean;
    locale: string;
}

export const TopPicksCarousel: React.FunctionComponent<
    TopPicksCarouselProps
> = ({ locale, isLoading, carouselEvents }) => {
    const carouselHeading = gettext('Top Picks');
    const carouselSubheading = gettext('The hottest tickets in town 🔥');
    const DSRPTopPicksEvents = carouselEvents.slice(0, 9);
    const segmentedCarouselControllerProps = useSegmentedCarouselController();

    if (!isLoading && !carouselEvents.length) {
        return null;
    }

    return (
        <section>
            <div className={styles.titleAndCarousel}>
                <div className={styles.carouselText}>
                    <Typography color="neutral-900" variant="heading-sm">
                        {carouselHeading.toString()}
                        <br />
                        <Typography
                            color="neutral-900"
                            variant="heading-xs"
                            className={styles.carouselSubheader}
                        >
                            {carouselSubheading.toString()}
                        </Typography>
                    </Typography>
                </div>
                {!isLoading ? (
                    <div className="dsrpCarousel">
                        <SegmentedCarousel
                            {...segmentedCarouselControllerProps}
                            visibleSegments={3}
                        >
                            <SegmentedCarousel.Header>
                                <SegmentedCarousel.Track>
                                    {DSRPTopPicksEvents.map((event, i) => (
                                        <div
                                            key={i}
                                            className={
                                                styles.DSRPTopPicksEventCards
                                            }
                                        >
                                            <RankedCardContainer
                                                event={event}
                                                key={i}
                                                locale={locale}
                                                rank={i + 1}
                                            />
                                        </div>
                                    ))}
                                </SegmentedCarousel.Track>
                            </SegmentedCarousel.Header>
                        </SegmentedCarousel>
                    </div>
                ) : (
                    <div className={styles.dsrpLoadingCards}>
                        <LoadingCards cardCount={1} style="grid" />
                        <LoadingCards cardCount={1} style="grid" />
                        <LoadingCards cardCount={1} style="grid" />
                    </div>
                )}
            </div>
        </section>
    );
};
