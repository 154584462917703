import { gettext } from '@eventbrite/i18n';
import { CloseIcon, IconButton, Typography } from '@eventbrite/marmalade';
import React, { MouseEventHandler } from 'react';
import styles from './FilterDrawerHeader.module.scss';

interface FilterDrawerHeaderProps {
    onCloseButtonClick: MouseEventHandler<HTMLElement>;
}

const FilterDrawerHeader: React.FunctionComponent<FilterDrawerHeaderProps> = ({
    onCloseButtonClick,
    children,
}) => {
    return (
        <div className={styles.headerContainer}>
            <div className={styles.headerContent}>
                <div className={styles.headerTitle}>
                    <Typography variant="heading-sm" color={'neutral-1000'}>
                        {gettext('Filters')}
                    </Typography>
                </div>
                <IconButton
                    onClick={onCloseButtonClick}
                    icon={<CloseIcon />}
                    aria-label={gettext('Close filter overlay')}
                />
            </div>
            {children}
        </div>
    );
};

export default FilterDrawerHeader;
