import { SearchResponseEventsData } from '@eventbrite/search-utils';
import React from 'react';
import FAQSectionContainer from '../FAQContainer/FAQSectionContainer';
import styles from './ExploratoryPageExtraContentContainer.module.scss';

interface TopPicksCarouselProps {
    events: SearchResponseEventsData;
}

export const ExploratoryPageExtraContentContainer = ({
    events,
}: TopPicksCarouselProps) => {
    return (
        <section className={styles.root}>
            <div className={styles.dsrpFAQSection}>
                <FAQSectionContainer events={events} />
            </div>
        </section>
    );
};
