import { gettext } from '@eventbrite/i18n';
import { ChevronDownIcon } from '@eventbrite/marmalade';
import { $FixMe } from '@eventbrite/ts-utils';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import LocationAutocompleteContainer from '../../../../../containers/LocationAutocompleteContainer/LocationAutocompleteContainer';
import { searchByBBoxOrPlace as searchByBBoxOrPlaceAction } from '../../../../../redux/actions/search';
import { DiscoverState } from '../../../../../types';
import './LocationPickerContainer.scss';

const LOCATION_AUTOCOMPLETE_PROPS = {
    borderType: 'content',
    id: 'locationPicker',
    name: 'locationPicker',
    hideOutline: true,
    isLimitedSearch: true,
    preventInputValuePrefill: true,
    shouldRemoveConflictiveAria: true,
};

const chevronIcon = <ChevronDownIcon color="#3659e3" />;

const DSRPLocationComponent = ({
    location,
    handleLocationSelect,
    currentPlace,
}: {
    location?: object;
    handleLocationSelect: Function;
    currentPlace: string;
}) => {
    const copy = gettext('in');

    return (
        <div className="dsrp-location">
            <div className="dsrp-location-text">{copy}</div>
            <span
                style={
                    {
                        '--character-count': currentPlace?.length || 10,
                    } as any
                }
            >
                <LocationAutocompleteContainer
                    autocompleteProps={LOCATION_AUTOCOMPLETE_PROPS}
                    shouldDispatchSearchActions={true}
                    location={location}
                    handleLocationSelect={handleLocationSelect}
                    suffix={chevronIcon}
                />
            </span>
        </div>
    );
};

const _mapStateToProps = (state: DiscoverState) => ({
    currentPlace: state.location.currentPlace,
});

const _mapDispatchToProps = (dispatch: Function) => ({
    handleLocationSelect: (...args: [$FixMe]) =>
        dispatch(searchByBBoxOrPlaceAction(...args)),
});

const enhance = compose<any>(
    withRouter,
    connect(_mapStateToProps, _mapDispatchToProps),
);

export const LocationPickerContainer = enhance(DSRPLocationComponent);
