import {
    EventInteraction,
    useExternalTabTracker,
    useTracking,
} from '@eventbrite/ads';
import { EventSearch } from '@eventbrite/discover-utils';
import { FormattedEvent } from '@eventbrite/event-renderer';
import { gettext } from '@eventbrite/i18n';
import React from 'react';
import { connect } from 'react-redux';
import { useEnvContext } from '../../../../../context';
import { DiscoverState } from '../../../../../types';
import { useSearchUpsell } from '../../../components/SearchVideoAdsUpsell';
import { useRemovePromotedSearchEvent } from '../../../SearchPageData';
import {
    aggregateTrackingContext,
    SEARCH_CONTENT_SUBINTERFACE,
} from '../../../utils/tracking';
import { SearchResultsPanelAdsUpsell } from '../../SearchResultPanelContent/components/SearchResultPanelAdsUpsell';
import SearchEventCardBucket from '../SearchEventCardBucket';
import styles from './PromotedEventsSection.module.scss';

interface PromotedEventsSectionProps {
    eventSearch: EventSearch;
    searchId: string;
    promotedEvents?: FormattedEvent[];
    isLoading: boolean;
    locale: string;
}

const PromotedEventsSection: React.FunctionComponent<
    PromotedEventsSectionProps
> = ({ eventSearch, searchId, promotedEvents = [], isLoading, locale }) => {
    const shouldShowUpsell = useSearchUpsell(promotedEvents);
    const { user, request } = useEnvContext();

    const { removeEvent: onRemoveEvent } =
        useRemovePromotedSearchEvent(eventSearch);

    const getTrackingContext = ({ id, event }: EventInteraction) =>
        aggregateTrackingContext({
            id,
            event,
            events: promotedEvents,
            eventSearch,
            searchId,
            userId: user?.publicId,
            locale,
            sessionId: request?.session_id,
            guestId: user?.guestId,
            subInterface: SEARCH_CONTENT_SUBINTERFACE,
        });

    const { ref } = useTracking<HTMLDivElement>(getTrackingContext);

    useExternalTabTracker(searchId, [eventSearch]);

    if (!isLoading && !promotedEvents.length) {
        return null;
    }

    return (
        <div className={styles.promotedEvents} ref={ref}>
            <SearchEventCardBucket
                events={promotedEvents}
                isLoading={isLoading}
                bucketName={gettext('Promoted events')}
                isPromoted
                onRemoveEvent={onRemoveEvent}
                getTrackingContext={getTrackingContext}
            />
            {shouldShowUpsell && <SearchResultsPanelAdsUpsell />}
        </div>
    );
};

const _mapStateToProps = (state: DiscoverState) => ({
    eventSearch: state.search.eventSearch,
    searchId: state.search.searchId,
    locale: state.app.locale,
});

export default connect(_mapStateToProps)(PromotedEventsSection);
