import { EventInteraction, EventInteractionContext } from '@eventbrite/ads';
import { PromotedActionsMenuEventCard } from '@eventbrite/ads-event-card-extra';
import { withTooltip } from '@eventbrite/eds-tooltip';
import { FormattedEvent } from '@eventbrite/event-renderer';
import { gettext } from '@eventbrite/i18n';
import { HelpIcon, Typography } from '@eventbrite/marmalade';
import React from 'react';
import { connect } from 'react-redux';
import DiscoverVerticalEventCard from '../../../../../components/DiscoverEventCard/DiscoverVerticalEventCard';
import { LoadingCards } from '../../../../../components/LoadingCards';
import { DiscoverState } from '../../../../../types';
import { ShareState } from '../../../../../types/index';
import styles from './SearchEventCardBucket.module.scss';

const HelpIconWithTooltip = withTooltip(HelpIcon);

interface SearchEventCardBucketProps {
    events: FormattedEvent[];
    isLoading?: boolean;
    locale: string;
    shareOptions: ShareState['options'];
    isAuthenticated: boolean;
    bucketName: string;
    isPromoted?: boolean;
    onRemoveEvent?: (eventId: string) => void;
    getTrackingContext?: (
        interaction: EventInteraction,
    ) => EventInteractionContext;
}

const SearchEventCardBucket: React.FunctionComponent<
    SearchEventCardBucketProps
> = ({
    events = [],
    isLoading,
    isPromoted,
    onRemoveEvent,
    getTrackingContext,
    locale,
    shareOptions,
    isAuthenticated,
    bucketName,
}) => {
    const statsigLocationString = isPromoted
        ? 'Search - Promoted'
        : 'Search - Primary Results';

    if (!isLoading && !events.length) {
        return null;
    }

    return (
        <section className={styles.searchCardBucket}>
            <div className={styles.searchCardBucketHeader}>
                <Typography as="h2" variant="heading-sm">
                    {bucketName}
                </Typography>
                {isPromoted && (
                    <span data-testid="promoted-bucket-tooltip">
                        <HelpIconWithTooltip
                            className={styles.promotedTooltipIcon}
                            tooltipId="promoted-tooltip-info"
                            tooltipStyle="phab"
                            tooltipText={
                                <Typography variant="body-sm">
                                    {gettext(
                                        'Event organizers pay for these premium spots so they can reach people searching for similar things to do.',
                                    )}
                                </Typography>
                            }
                            tooltipArrowLocation="left"
                        />
                    </span>
                )}
            </div>
            <div className={styles.searchCardBucketGrid}>
                {isLoading ? (
                    <>
                        <LoadingCards cardCount={1} style="grid" />
                        <LoadingCards cardCount={1} style="grid" />
                        <LoadingCards cardCount={1} style="grid" />
                    </>
                ) : (
                    events.map((event) => (
                        <DiscoverVerticalEventCard
                            key={event.id}
                            event={event}
                            statsigLocationString={statsigLocationString}
                            isAuthenticated={isAuthenticated}
                            affCode="ebdssbdestsearch"
                            shareOptions={{
                                ...shareOptions,
                                eventId: event.id,
                                eventName: event.name,
                            }}
                            locale={locale}
                            moreActions={
                                event.isPromoted &&
                                onRemoveEvent &&
                                getTrackingContext && (
                                    <PromotedActionsMenuEventCard
                                        event={event}
                                        onRemoveEvent={onRemoveEvent}
                                        getTrackingContext={getTrackingContext}
                                    />
                                )
                            }
                            hasFullClick
                        />
                    ))
                )}
            </div>
        </section>
    );
};

const _mapStateToProps = (state: DiscoverState) => ({
    locale: state.app.locale,
    shareOptions: state.share?.options,
    isAuthenticated: state.auth.isAuthenticated,
});

export default connect(_mapStateToProps)(SearchEventCardBucket);
