import React from 'react';
import { AnimatedDrawer } from '../../../components/AnimatedDrawer';
import { FilterHeaderContainer } from '../../FilterHeaderContainer/FilterHeaderContainer';
import styles from './FilterDrawer.module.scss';
import FilterDrawerFooter from './FilterDrawerFooter';
import FilterDrawerHeader from './FilterDrawerHeader';
import './FilterStyleOverrides.scss';

interface FilterDrawerProps {
    isAnimatedDrawerOpen?: boolean;
    openCloseAnimatedDrawer?: Function;
}

export const FilterDrawer: React.FunctionComponent<FilterDrawerProps> = ({
    children,
    isAnimatedDrawerOpen,
    openCloseAnimatedDrawer,
}) => {
    if (isAnimatedDrawerOpen) {
        return (
            <AnimatedDrawer showOverlay>
                <section
                    data-testid="filter-panel-mobile-container"
                    className={styles.drawerContainer}
                >
                    <FilterDrawerHeader
                        onCloseButtonClick={() =>
                            openCloseAnimatedDrawer?.(false)
                        }
                    >
                        <div className={styles.filterPills}>
                            <FilterHeaderContainer
                                hideActiveFilterLabel={true}
                                shouldRenderInactivePills={false}
                            />
                        </div>
                    </FilterDrawerHeader>
                    <div className={styles.filterContentContainer}>
                        {children}
                    </div>
                    <FilterDrawerFooter
                        onViewResultsClick={() =>
                            openCloseAnimatedDrawer?.(false)
                        }
                    />
                </section>
            </AnimatedDrawer>
        );
    } else {
        return null;
    }
};

export default FilterDrawer;
