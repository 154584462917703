import type { SearchResponseEventsData } from '@eventbrite/search-utils';
import React from 'react';
import { LocationPickerContainer } from '../ExploratoryPageResultsContainer/LocationPickerContainer/LocationPickerContainer';
import { TopPicksCarousel } from '../ExploratoryPageResultsContainer/TopPicksCarousel/TopPicksCarousel';
import { FilterHeaderContainer } from '../FilterHeaderContainer';
import FilterPanelContainer from '../FilterPanelContainer';
import styles from './ExploratoryPageResultsContainer.module.scss';
import PromotedEventsSection from './PromotedEventsSection';
import { SearchHeaderContainer } from './SearchHeaderContainer';

interface ExploratoryPageResultsContainerProps {
    events: SearchResponseEventsData;
    isLoading: boolean;
    locale: string;
}

export const ExploratoryPageResultsContainer = ({
    isLoading,
    locale,
    events,
}: ExploratoryPageResultsContainerProps) => {
    return (
        <div className={styles.root}>
            <div className={styles.resultsSection}>
                <section className={styles.header}>
                    <SearchHeaderContainer />
                    <LocationPickerContainer />
                    <FilterHeaderContainer isExploratoryDSRP />
                    <FilterPanelContainer isExploratoryDSRP />
                </section>
                <PromotedEventsSection
                    promotedEvents={events.promoted}
                    isLoading={isLoading}
                />
                <TopPicksCarousel
                    carouselEvents={events.organic}
                    locale={locale}
                    isLoading={isLoading}
                />
            </div>
        </div>
    );
};
