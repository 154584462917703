import { withOverlayControls } from '@eventbrite/eds-structure';
import { Typography } from '@eventbrite/marmalade';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { usePrevious } from 'react-use';
import { LOCATION_DIALOG_CONTENT } from '../../../../../constants';
import { getSearchHeaderTitle } from '../../../../../redux/selectors/search';
import { DiscoverState } from '../../../../../types';
import styles from './SearchHeaderContainer.module.scss';

interface SearchHeaderProps {
    showOverlay: Function;
    searchHeaderTitle?: string;
    locationDenied?: boolean;
}

export const SearchHeader: React.FunctionComponent<SearchHeaderProps> = (
    props,
) => {
    const { searchHeaderTitle, locationDenied, showOverlay } = props;

    const prevLocationDenied = usePrevious(locationDenied);

    useEffect(() => {
        if (locationDenied && locationDenied !== prevLocationDenied) {
            showOverlay('dialog', LOCATION_DIALOG_CONTENT);
        }
    }, [locationDenied, showOverlay, prevLocationDenied]);

    return (
        <header className={styles.headerTitle}>
            <Typography as="h1" variant="heading-lg">
                {searchHeaderTitle}
            </Typography>
        </header>
    );
};

const _mapStateToProps = (state: DiscoverState) => ({
    searchHeaderTitle: getSearchHeaderTitle(state),
    locationDenied: state.location?.locationDenied,
});

export const SearchHeaderContainer = withOverlayControls<any>(
    connect(_mapStateToProps)(SearchHeader),
);
