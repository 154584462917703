import React, { useEffect } from 'react';

import { GenericLazyString, gettext } from '@eventbrite/i18n';
import classNames from 'classnames';

import { getPaginationInfo } from './lib';

import {
    ChevronLeftIcon,
    ChevronRightIcon,
    IconButton,
    IconButtonProps,
} from '@eventbrite/marmalade';

import { DEFAULT_RESULTS_PER_PAGE } from './constants';

import styles from './Pagination.module.scss';

interface PaginationPagesProps {
    items: any[];
    pageCount: number;
}
const PaginationPages = ({ items, pageCount }: PaginationPagesProps) => {
    const pageNum = items[0].number;

    // FIXME get span color working
    const style = styles['search-pagination__navigation-pages--current-page'];
    const gettextWithMarkup = {
        __html: gettext(
            '<span data-testid="pagination-string">%(pageNum)s</span> of %(pageCount)s',
            { pageNum, pageCount, classNames: style },
        ).toString(),
    };

    return (
        <li
            className={styles['search-pagination__navigation-minimal']}
            data-testid="pagination-parent"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={gettextWithMarkup}
        />
    );
};

interface PaginationPageProps {
    isSelected?: boolean;
    isDisabled: boolean;
    onSelected: Function;
    ariaLabel: string | GenericLazyString;
    icon: IconButtonProps['icon'];
    'data-testid': string;
    show?: boolean;
    isLoading?: boolean;
}
const PaginationPage = ({
    isSelected = false,
    isDisabled,
    onSelected,
    ariaLabel,
    icon,
    'data-testid': testid,
    isLoading = false,
}: PaginationPageProps) => {
    const className = classNames(styles['search-pagination__navigation-page'], {
        [styles['search-pagination__navigation-page--is-selected']]: isSelected,
        [styles['search-pagination__navigation-page--is-disabled']]: isDisabled,
    });

    return (
        <li className={className} data-testid={`${testid}-wrapper`}>
            {!isDisabled && (
                <IconButton
                    tabIndex={0}
                    disabled={isLoading || isDisabled}
                    onClick={onSelected as IconButtonProps['onClick']}
                    icon={icon}
                    aria-label={ariaLabel}
                    data-testid={testid}
                />
            )}
        </li>
    );
};

interface PaginationProps {
    /**
     * Page number (will default/override internal state).
     * a.k.a. current page number
     */
    pageNumber: number;
    /**
     * Total number of results.
     * Either totalNumResults or pageCount are required props.
     * Since the pageCount prop is on path to deprecation, using totalNumResults is recommended.
     */
    totalNumResults: number;
    /**
     * isLoading prop to be passed to the pagination page buttons
     * This prop is used to disable the pagination buttons when the component is in a loading state
     */
    isLoading?: boolean;
    /**
     * Callback function to be invoked on select
     */
    onSelected: Function;
}
export default function Pagination({
    pageNumber = 1,
    totalNumResults,
    isLoading = false,
    onSelected,
}: PaginationProps) {
    const resultsPerPage = DEFAULT_RESULTS_PER_PAGE;

    const [currentPage, setCurrentPage] = React.useState(pageNumber);

    useEffect(() => {
        if (pageNumber !== currentPage) {
            setCurrentPage(pageNumber);
        }
    }, [currentPage, pageNumber]);

    const pageInfo = getPaginationInfo({
        pageNumber: currentPage,
        totalNumResults,
        currentResultsPerPage: resultsPerPage,
    });

    const _handleSelected = (selectedPageNumber: number) => {
        if (
            selectedPageNumber < 1 ||
            selectedPageNumber > pageInfo.pageCount ||
            selectedPageNumber === currentPage
        ) {
            return;
        }

        setCurrentPage(selectedPageNumber);
        onSelected(selectedPageNumber);
    };

    const paginationClassName = styles['search-pagination'];
    const paginationResultsClassName = styles['search-pagination__results'];

    if (pageInfo.pageCount <= 1) return null;

    return (
        <div className={paginationClassName} data-testid="pagination">
            <div className={paginationResultsClassName}>
                <ul className={styles['search-pagination__navigation-group']}>
                    <PaginationPage
                        isDisabled={pageInfo.isFirstPage}
                        onSelected={() => _handleSelected(currentPage - 1)}
                        isLoading={isLoading}
                        data-testid="page-prev"
                        icon={<ChevronLeftIcon size="small" />}
                        ariaLabel={gettext('Previous Page')}
                    />

                    <PaginationPages
                        items={pageInfo.pages}
                        pageCount={pageInfo.pageCount}
                    />

                    <PaginationPage
                        isDisabled={pageInfo.isLastPage}
                        onSelected={_handleSelected.bind(null, currentPage + 1)}
                        isLoading={isLoading}
                        data-testid="page-next"
                        icon={<ChevronRightIcon size="small" />}
                        ariaLabel={gettext('Next Page')}
                    />
                </ul>
            </div>
        </div>
    );
}
