import {
    EventSearch,
    SearchExploratoryDSRPExperiment,
} from '@eventbrite/discover-utils';
import {
    withMainControls,
    WithMainControlsProps,
} from '@eventbrite/eds-structure';
import { FormattedEvent } from '@eventbrite/event-renderer';
import { $FixMe } from '@eventbrite/ts-utils';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { RenderOnViewport } from '../../components/RenderOnViewport';
import { useServerEnrolledStatsigExperiment } from '../../context/experiments';
import {
    logSearchMetrics,
    trackSearchPageView,
} from '../../redux/actions/analytics';
import {
    DiscoverState,
    FeatureFlags,
    PaginationData,
    ThingsToDoShelfState,
    TrendingSearchesResultState,
} from '../../types';
import { getSearchSection } from '../../utils/general';
import {
    SearchVideoAdsUpsell,
    useSearchUpsell,
} from './components/SearchVideoAdsUpsell';
import { ExploratoryPageExtraContentContainer } from './containers/ExploratoryPageExtraContentContainer';
import { ExploratoryPageResultsContainer } from './containers/ExploratoryPageResultsContainer';
import { FAQSectionContainer } from './containers/FAQContainer';
import { LazyMapPanelContainer } from './containers/MapPanelContainer';
import { SearchBasedRecommendedEvents } from './containers/SearchRelatedEvents/SearchBasedRecommendedEvents';
import { SearchResultsPanelContainer } from './containers/SearchResultsPanelContainer';
import { ThingsToDoShelfContainer } from './containers/ThingsToDoShelfContainer';
import { TrendingSearchesContainer } from './containers/TrendingSearchesContainer';
import { useMapExperiment } from './hooks';
import styles from './SearchPageContent.module.scss';
import {
    useErrorHandlingEffects,
    useEventSearchSyncEffects,
    useSearchBaseTrackingEffects,
} from './SearchPageContentEffects';
import './SearchPageContentOverrides.scss';
import {
    SearchCachedData,
    SearchDataParams,
    useSearchData,
} from './SearchPageData';

type SearchPageContentReduxProps = RouteComponentProps &
    WithMainControlsProps & {
        locale: string;
        eventSearch: EventSearch;
        dispatch: Function;
        searchContext: SearchDataParams['searchContext'];
        /**
         * GA Utility for tracking the pageview of search
         */
        onTrackPageView: (
            events: FormattedEvent[],
            pagination: PaginationData,
            topMatchShown: boolean,
        ) => void;
        /**
         * Util for tracking search updates
         */
        logMetrics: (data: SearchCachedData) => void;
        /**
         * Type of individual filter to show in the filter panel
         * or '' if all filters must be shown
         */
        filterTypeToShow?: $FixMe;
        isAuthenticated?: boolean;
        showErrorNotification?: boolean;
        isBotRequest: boolean;
        isOnline: boolean;
        thingsToDoShelf: ThingsToDoShelfState;
        trendingSearchesResult: TrendingSearchesResultState;
        featureFlags: FeatureFlags;
    };
function _SearchPageContent({
    locale,
    history,
    location,
    isBotRequest,
    isOnline,
    eventSearch,
    searchContext,
    dispatch,
    logMetrics,
    onTrackPageView,
    isAuthenticated = false,
    showErrorNotification,
    addMainNotification,
    hideMainNotification,
    thingsToDoShelf,
    trendingSearchesResult,
    featureFlags,
}: SearchPageContentReduxProps) {
    const useBff = featureFlags?.enableSearchBff;

    const { events, pagination, isLoading, error } = useSearchData({
        eventSearch,
        searchContext,
        onNewData: logMetrics,
        useBff,
    });

    const showUpsell = useSearchUpsell(events.promoted);

    useEventSearchSyncEffects({
        eventSearch,
        dispatch,
        history,
        thingsToDoShelf,
        trendingSearchesResult,
    });
    useSearchBaseTrackingEffects({
        onTrackPageView,
        isAuthenticated,
        pagination,
        events,
        location,
    });
    useErrorHandlingEffects({
        error,
        dispatch,
        showErrorNotification,
        addMainNotification,
        hideMainNotification,
    });

    const isMapExperimentEnabled = useMapExperiment();

    const exploratoryDSRPVariant = useServerEnrolledStatsigExperiment({
        name: SearchExploratoryDSRPExperiment.name,
        paramName: SearchExploratoryDSRPExperiment.paramName,
        defaultValue: SearchExploratoryDSRPExperiment.values.control,
    });

    const isExploratoryDSRPEnabled =
        exploratoryDSRPVariant.variant ===
            SearchExploratoryDSRPExperiment.values.variant && !isBotRequest;

    return (
        <>
            {isExploratoryDSRPEnabled ? (
                <ExploratoryPageResultsContainer
                    events={events}
                    isLoading={isLoading}
                    locale={locale}
                />
            ) : (
                <div
                    className={classNames(
                        styles.root,
                        'search-base-screen--marmalade-filters',
                    )}
                >
                    <section className={styles.searchPanel}>
                        <SearchResultsPanelContainer
                            events={events}
                            pagination={pagination}
                            isLoading={isLoading}
                        />
                    </section>
                    {!isMapExperimentEnabled && (
                        <section
                            className={styles.mapPanel}
                            data-id="search-map-panel"
                        >
                            {!isOnline && (
                                <LazyMapPanelContainer
                                    events={events}
                                    isOnline={isOnline}
                                    isLoading={isLoading}
                                />
                            )}
                        </section>
                    )}
                </div>
            )}
            {isExploratoryDSRPEnabled ? (
                <section>
                    <ExploratoryPageExtraContentContainer events={events} />
                </section>
            ) : (
                <section className={styles.extraContent}>
                    {showUpsell && (
                        <SearchVideoAdsUpsell className={styles.videoUpsell} />
                    )}
                    <ThingsToDoShelfContainer />
                    <RenderOnViewport
                        options={{
                            rootMargin: '300px',
                        }}
                    >
                        <SearchBasedRecommendedEvents
                            className={styles.searchPageShelf}
                            locale={locale}
                            isAuthenticated={isAuthenticated}
                            eventSearch={eventSearch}
                        />
                    </RenderOnViewport>
                    <TrendingSearchesContainer />
                    <FAQSectionContainer events={events} />
                </section>
            )}
        </>
    );
}

const _mapStateToProps = (state: DiscoverState) => ({
    locale: state.app.locale,
    eventSearch: state.search.eventSearch,
    searchContext: {
        isStaff: state.user.isStaff,
        experimentOverride: state.admin.experimentOverride,
    },
    eventsContext: {
        section: getSearchSection(state.location.isOnline),
        currencyFormat: state.currencyFormat,
    },
    showErrorNotification: state.ui.showErrorNotification,
    thingsToDoShelf: state.thingsToDoShelf,
    trendingSearchesResult: state.trendingSearchesResult,
    featureFlags: state.app.featureFlags,

    //Props below for withEventSaveNotification HoC
    isAuthenticated: state.auth.isAuthenticated,
    isOnline: state.location.isOnline,
    isBotRequest: state.app.isBotRequest,
});

const _mapDispatchToProps = (dispatch: Function) => ({
    onTrackPageView: (
        events: FormattedEvent[],
        pagination: PaginationData,
        topMatchShown: boolean,
    ) => dispatch(trackSearchPageView(events, pagination, topMatchShown)),
    logMetrics: () => dispatch(logSearchMetrics()),
    dispatch,
});

export const SearchPageContent: FunctionComponent = compose<$FixMe>(
    withRouter,
    withMainControls,
    connect(_mapStateToProps, _mapDispatchToProps),
)(_SearchPageContent);
