import { gettext } from '@eventbrite/i18n';
import { Button } from '@eventbrite/marmalade';
import React, { MouseEventHandler } from 'react';
import styles from './FilterDrawerFooter.module.scss';

interface FilterDrawerFooterProps {
    onViewResultsClick: MouseEventHandler<HTMLElement>;
}

const FilterDrawerFooter: React.FunctionComponent<FilterDrawerFooterProps> = ({
    onViewResultsClick,
}) => (
    <section className={styles.buttonContainer}>
        <Button
            onClick={onViewResultsClick}
            variant="primary"
            style={{ width: '100%' }}
        >
            {gettext('View event results')}
        </Button>
    </section>
);

export default FilterDrawerFooter;
